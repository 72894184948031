// import { useState, useCallback, useRef } from 'react';
// import useVideoContext from '../useVideoContext/useVideoContext';
// import { LogLevels, Track } from 'twilio-video';

// interface MediaStreamTrackPublishOptions {
//   name?: string;
//   priority: Track.Priority;
//   logLevel: LogLevels;
// }

// export default function useScreenShareToggle() {
//   const { room, onError } = useVideoContext();
//   const [isSharing, setIsSharing] = useState(false);
//   const stopScreenShareRef = useRef<() => void>(null!);

//   const shareScreen = useCallback(() => {
//     navigator.mediaDevices
//       .getDisplayMedia({
//         audio: false,
//         video: {
//           frameRate: 10,
//           height: 1080,
//           width: 1920,
//         },
//       })
//       .then(stream => {
//         var screenTrack = stream.getVideoTracks()[0];
//         // All video tracks are published with 'low' priority. This works because the video
//         // track that is displayed in the 'MainParticipant' component will have it's priority
//         // set to 'high' via track.setPriority()
//         // room.localParticipant
//           // .publishTrack(track, {
//           //   name: 'screen', // Tracks can be named to easily find them later
//           //   priority: 'low', // Priority is set to high by the subscriber when the video track is rendered
//           // } as MediaStreamTrackPublishOptions)
//           room.localParticipant
//           .publishTrack(screenTrack)
//           .then(trackPublication => {
//             stopScreenShareRef.current = () => {
//               room.localParticipant.unpublishTrack(screenTrack);
//               // TODO: remove this if the SDK is updated to emit this event
//               room.localParticipant.emit('trackUnpublished', trackPublication);
//               screenTrack.stop();
//               setIsSharing(false);
//             };

//             screenTrack.onended = stopScreenShareRef.current;
//             setIsSharing(true);
//           })
//           .catch(onError);
//       })
//       .catch(error => {
//         if (getOS() === 'Mac OS') {
//           if (error.message === 'Permission denied by system') {
//             let modal = document.getElementById('suggestion_modal');
//             if (modal) {
//               modal.classList.add('show-modal');
//             }
//           }
//         }
//         // Don't display an error if the user closes the screen share dialog
//         if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
//           onError(error);
//         }
//       });
//     function getOS() {
//       var userAgent = window.navigator.userAgent,
//         platform = window.navigator.platform,
//         macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
//         windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
//         iosPlatforms = ['iPhone', 'iPad', 'iPod'],
//         os = null;

//       if (macosPlatforms.indexOf(platform) !== -1) {
//         os = 'Mac OS';
//       } else if (iosPlatforms.indexOf(platform) !== -1) {
//         os = 'iOS';
//       } else if (windowsPlatforms.indexOf(platform) !== -1) {
//         os = 'Windows';
//       } else if (/Android/.test(userAgent)) {
//         os = 'Android';
//       } else if (!os && /Linux/.test(platform)) {
//         os = 'Linux';
//       }

//       return os;
//     }
//   }, [room, onError]);

//   const toggleScreenShare = useCallback(() => {
//     !isSharing ? shareScreen() : stopScreenShareRef.current();
//   }, [isSharing, shareScreen, stopScreenShareRef]);

//   return [isSharing, toggleScreenShare] as const;
// }

import { useState, useCallback, useRef } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import { LogLevels, Track } from 'twilio-video';

interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
  logLevel: LogLevels;
}

export default function useScreenShareToggle() {
  const { room, onError } = useVideoContext();
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef<() => void>(null!);
  const screenVideoRef = useRef<HTMLVideoElement | null>(null);

  const shareScreen = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: false,
        video: {
          frameRate: 10,
          height: 1080,
          width: 1920,
        },
      })
      .then(stream => {
        const screenTrack = stream.getVideoTracks()[0];
        room.localParticipant
          .publishTrack(screenTrack)
          .then(trackPublication => {
            stopScreenShareRef.current = () => {
              room.localParticipant.unpublishTrack(screenTrack);
              // TODO: remove this if the SDK is updated to emit this event
              room.localParticipant.emit('trackUnpublished', trackPublication);
              screenTrack.stop();
              if (screenVideoRef.current) {
                document.body.removeChild(screenVideoRef.current);
                screenVideoRef.current = null;
              }
              setIsSharing(false);
            };

            screenTrack.onended = stopScreenShareRef.current;

            // Create a new video element to display the screen share
            const videoElement = document.createElement('video');
            videoElement.autoplay = true;
            videoElement.muted = true;
            videoElement.style.position = 'absolute';
            videoElement.style.top = '30%';
            videoElement.style.right = '30%';
            videoElement.style.width = '39%';
            document.body.appendChild(videoElement);
            videoElement.srcObject = new MediaStream([screenTrack]);

            screenVideoRef.current = videoElement;

            setIsSharing(true);
          })
          .catch(onError);
      })
      .catch(error => {
        if (getOS() === 'Mac OS') {
          if (error.message === 'Permission denied by system') {
            let modal = document.getElementById('suggestion_modal');
            if (modal) {
              modal.classList.add('show-modal');
            }
          }
        }
        // Don't display an error if the user closes the screen share dialog
        if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
          onError(error);
        }
      });

    function getOS() {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;
      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
      const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
      let os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }

      return os;
    }
  }, [room, onError]);

  const toggleScreenShare = useCallback(() => {
    !isSharing ? shareScreen() : stopScreenShareRef.current();
  }, [isSharing, shareScreen, stopScreenShareRef]);

  return [isSharing, toggleScreenShare] as const;
}
