import React, { createContext, useContext, useReducer, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
import { User } from 'firebase';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<any>;
  startCall(meetingid: string, passcode?: string): Promise<any>;
  endCall(meetingid: string, passcode?: string): Promise<any>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useState('default');
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (identifierName, roomName, userToken) => {
        //2
        //const headers = new window.Headers();
        //const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
        //const params = new window.URLSearchParams({ identifierName, roomName });

        //return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());

        //3
        const endpoint = `https://devapi.vyzeo.com/v1/twilio/twilio-access-token`;
        // const endpoint = `https://devapi.vyzeo.com/v1/twilio/twilio-access-token`;
        return fetch(`${endpoint}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            identifierName: identifierName,
            roomName: roomName,
          }),
        }).then(res => res.json());
      },
      startCall: async (meetingId, userToken) => {
        const endpoint = `https://devapi.vyzeo.com/v1/meeting/instant-call-start`;
        return fetch(`${endpoint}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            meeting_id: meetingId,
          }),
        }).then(res => res.json());
      },
      endCall: async (meetingId, userToken) => {
        const endpoint = `https://devapi.vyzeo.com/v1/meeting/call-end`;
        return await fetch(`${endpoint}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            meeting_id: meetingId,
            meeting_status: 1,
          }),
        });
        //.then(res => res.json());
      },
    };
  }

  const getToken: StateContextType['getToken'] = (name, room, user_token) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room, user_token)
      .then(res => {
        setIsFetching(false);

        console.log('token response form vyzeo==>', res);
        let token = '';
        if (res && res.token) {
          token = res.token;
        } else {
          setError(res);
        }

        return token;
      })
      .catch(err => {
        console.log(`getToken Error ${err}`);
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const startCall: StateContextType['startCall'] = (meeting_id, user_token) => {
    return contextValue
      .startCall(meeting_id, user_token)
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log('start call api err=>', err);
        return Promise.reject(err);
      });
  };

  const endCall: StateContextType['endCall'] = (meeting_id, user_token) => {
    return contextValue
      .endCall(meeting_id, user_token)
      .then(res => {
        console.log('end call api res=>', res);
        return res;
      })
      .catch(err => {
        console.log('end call api err=>', err);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, startCall, endCall }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
