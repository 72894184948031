import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { useParams } from 'react-router-dom';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../../state';
import { VYZEO_REDIRECT_URL } from '../../../state/settings/config';

//import { leaveSocketRoom } from '../../../socket/socket'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { endCall } = useAppState();
  const { roomName, token } = useParams();

  const handleEndCall = async () => {
    if (roomName && token) {
      endCall(roomName, token).then((res: any) => {
        setTimeout(() => {
          room.disconnect();
          window.location.assign(VYZEO_REDIRECT_URL);
        }, 2000);
      });
    }
    // leaveSocketRoom(roomName);
    //window.location.assign(`${process.env.VYZEO_REDIRECT_ENDPOINT}`)
  };

  return (
    <Tooltip title={'End Call'} onClick={handleEndCall} placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={classes.fab} color="primary">
        <CallEnd />
      </Fab>
    </Tooltip>
  );
}
